@media screen and (max-width: 1440px){
    .template{
        min-width: 30%;
    }
}

@media screen and (max-width: 580px){
    .template{
        min-width: 100%;
    }
}



