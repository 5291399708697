.criteria-container {
  max-height: 500px;
  overflow-y: auto;
}
.segment-dropdown .dropdown-toggle {
  background-color: var(--falcon-quaternary-bg) !important;
  border: 2px solid var(--falcon-gray-300) !important;
  border-radius: 4px;
  color: var(--falcon-text-color);
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  box-shadow: none !important;

  &.show,
  &:hover {
    border: 2px solid #2c7be5 !important;
  }

  &::after {
    position: absolute;
    right: 8px;
    top: 50%;
  }
}

.search-input-value {
  background-color: var(--falcon-quaternary-bg) !important;
  color: var(--falcon-text-color) !important;
}

.input-value {
  width: 100%;
  border: 0;
  border: 2px solid var(--falcon-gray-300);
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  padding: 5px 8px;
  background-color: #fff;

  @media (min-width: 576px) {
    max-width: 300px;
  }

  &:focus {
    box-shadow: none;
    border: 2px solid #2c7be5;
  }
  &.error {
    border: 2px solid #f44336;
  }
}

.segment {
  border: 1px solid var(--falcon-gray-300);
  border-radius: 0.25rem;
  padding: 24px;
}

.segment-dropdown.error .dropdown-toggle {
  border-bottom: 2px solid #f44336 !important;
}
.vertical-line {
  height: 16px;
  width: 1px;
  background-color: var(--falcon-gray-300);
}
.criteria {
  width: 70%;
  padding: 16px 24px;
  background-color: var(--falcon-gray-300);
  border-radius: 0.25rem;
  margin-top: 4px;
  position: relative;
  padding-right: 48px;
  .action {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    display: flex;
    gap: 8px;
    font-size: 18px;
    .icon {
      cursor: pointer;
      &.edit:hover {
        color: #2c7be5;
      }
      &.delete:hover {
        color: #f44336;
      }
    }
  }
}
.and-or-buttons {
  border: 1px solid var(--falcon-gray-300);
  border-radius: 0.25rem;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  display: flex;
  gap: 4px;
}
.and-or-button {
  outline: none;
  background-color: white;
  border: none;
  color: #2c7be5;
  font-size: 14px;
  padding: 2px 4px;
  cursor: pointer;
  &.active {
    background-color: #2c7be5;
    color: white;
    border-radius: 0.25rem;
  }
  &.absolute {
    position: absolute;
    top: -50%;
    left: 24px;
    transform: translateY(50%);
  }
}

@media screen and (max-width: 768px) {
  .criteria {
    width: 100%;
  }
}
