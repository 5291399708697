#dropdown-item-button{
  background-color: #fff;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 0;
  color: #000;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  &.show{
    box-shadow: none;
    border-bottom: 2px solid #2c7be5;

  }
  &::after{
    position: absolute;
    right: 0;
    top: 50%;

  }
}
.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.segment-table .table-responsive{
  max-height: 500px;
  overflow-y: auto;
}
.segment-table th {
  position: sticky;
  top: 0;
  left: 0;
}

tr.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
