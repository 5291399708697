.upload-box{
  width: 100%;
  min-height: 150px;
  border: 2px solid #748194;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  padding: 20px 32px;
  p{
    font-size: 14px;
  }
}

.upload-text{
  text-align: center;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  &:hover{
    text-decoration: underline;
  }
}
.icon-delete{
  color: #748194;
  cursor: pointer;
  font-size: 32px;
  margin-top: 30px;
  &:hover{
    color: #f44336;
  }
}
.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-toggle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px !important;
}
.box{
  border: 1px solid var(--falcon-gray-300);
  border-radius: 0.25rem;
  padding: 16px;
}
.MuiInputBase-input{
  padding: 7px 16px !important;
  color: var(--falcon-gray-900) !important;
  font-size: 1rem !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

}
.MuiFormControl-root{
  width: 100%;
}
.font-bold{
  font-weight: 600;
}
.segment-row .accordion-body, .sub-title{
  padding: 0 !important;
}
.segment-row .accordion{
  // width: max-content;
  width: 100%;
}
.segment-row .accordion-item{
  min-width: 860px;
}
.segment-row .accordion-item {
  border-top: none !important;
}
.form-control{
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 16px !important;
}
.react-datetime-picker__wrapper{
  border: none !important;
}


.segment-table .table-responsive{
  max-height: 500px;
  overflow-y: auto;
}
.segment-table th {
  position: sticky;
  top: 0;
  left: 0;
}
